// Header - Background-colour + layout
.pageHeader .headerContent {
  position: relative;
  background-color: $white;
  @media (min-width: map-get($breakpoints, md) + 1px) {
    width: calc(100% - #{$site-bleed * 2});
  }
}

// Tagline - Layout + format
.pageHeader .headerContent .tagline {
  flex-grow: 0;
  margin-right: auto;
  line-height: $line-height-base;
  span { color: $brand-secondary; }
}

// Header button - Icon
// .mainCallToAction .cta-button.donate:before {
//   content: '\f095';
//   font-family: $icon-font-family;
//   font-size: 1em;
//   color: $donate-btn-colour;
//   margin-right: 5px;
// }

// Header - Buttons size
@media (min-width: calc(480px + 1px)) {
  .menuMainAlt,
  .headerContent .mainCallToAction .cta-button {
    font-size: $font-size-large;
  }
}

// Social icon Phone - Bigger screen
@media (min-width: #{$header-social-icons-breakpoint + 1px}) {
  .headerContent ul.socialIcons a {
    width: $header-search-button-width;
    height: $header-search-button-height;
    background-color: $brand-secondary;
    &:before { font-size: $header-search-button-icon-font-size; }
  }
}

// Social icon Phone - Smaller screen
@media (max-width: $header-social-icons-breakpoint) {
  .mainCallToAction .socialIcons a.phone {
    width: auto;
    font-size: $nav-submenu-item-font-size;
    color: $nav-normal-mobile-submenu-item-colour;
    &:before { margin-right: $spacer; }
    &:hover { background-color: transparent; }
  }
}

// Social icon - Phone
ul.socialIcons a.phone:before {
  content: '\f095';
}

// Header - Spacing layout
#bodyForm {
  padding-top: $gap-width;
}

// Header - Mobile layout
@media (max-width: $cta-btn-breakpoint) {
  .pageHeader .headerContent {
    padding-top: $header-content-padding-top * 0.5;
    padding-bottom: calc(#{$header-content-padding-bottom * 0.5} + 40px);
  }
}

// Mobile menu - Layout
.menuMain .header-search:first-of-type {
  display: none;
}

// Carousel - Shadow
.carouselSlide {
  box-shadow: 0 1px 3px rgba($black, 0.15);
}

// Home Intro - Layout
.homeIntro {
  padding: 0 $spacer;
}

// Home Intro - Spacing mobile
@media (max-width: map-get($breakpoints, lg)) {
  .homeIntroWrapper {
    margin-bottom: $gap-width;
  }
}

// Home Box - Search snippet
.homeFeatures .homeFeature.homeBox2 {
  padding: $card-details-padding;
  background-color: $donate-colour;
  color: $white;
  box-shadow: 0 4px 0px 0px darken($donate-colour, 10%);
  justify-content: center;
  // Search
  #siteSearch {
    width: calc(100% - #{$header-search-button-width});
    border-width: 0px;
  }
  .header-search button { background-color: darken($donate-colour, 10%); }
  .header-search button:hover { background-color: darken($donate-colour, 17.5%); }
}

// Search feature - Smaller screens
@media (max-width: $header-search-breakpoint) {
  .menuMain .header-search #siteSearch,
  .homeFeatures .homeFeature.homeBox2 #siteSearch {
    height: 40px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
  .header-search button {
    width: 40px;
    height: 40px;
    &:before { font-size: #{$social-icons-font-size * 0.85}; }
  }
}

// Footer - Layout
.pageFooterWrapper {
  box-shadow: 0 1px 3px rgba($black, 0.15);
  border-bottom: 4px solid $brand-primary;
}

// Footer - Format
.pageFooter {
  width: 100%;
  max-width: calc(#{$container-max-width} - #{$site-bleed * 2});
  display: flex;
  flex-wrap: wrap;
  padding-left: $spacer;
  padding-right: $spacer;
  .footerBox {
    margin: 0;
    flex: 1;
    min-width: $card-min-width;
    margin-bottom: $spacer * 2;
  }
}

// Footer - Headings
.footerBox h2,
.footerBox h3,
.footerBox h4 {
  @include heading-underline(
    $colour: $footer-colour,
    $width: 3.25em,
    $height: 2px,
    $margin-top: 0.125em,
    $margin-bottom: $headings-margin-bottom
  );
}

// Inner pages
body:not(.homepage) {
  background-color: $white;
}

// Breadcrumbs
.breadcrumbItem a {
  font-weight: $font-weight-bold;
  color: $brand-secondary;
}

// Back to top Button
.button.backToTop {
  background-color: $brand-secondary;
  &:hover { background-color: darken($brand-secondary, 10%); }
}

// Quick Giving Panel - Donation selected
.quickGivingPanel .donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 2px $donate-colour;
}

// Quick Giving - Donate button
.button.donateBtn {
  background-color: $donate-colour;
  &:hover { background-color: darken($donate-colour, 10%); }
}

// Footer Logos - Setup
.footerBox[class*="ogo"] {
  margin: 0;
  padding: $footer-padding-y $site-bleed;
  background-color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 120px;
    margin: 10px 20px;
  }
}

// Tagline in Menu - Mobile
.menuMain .tagline {
  background-color: $brand-secondary;
  margin-right: 0;
  padding: $nav-submenu-item-padding;
  color: $nav-normal-mobile-submenu-item-colour;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  p { margin-bottom: 0; }
  span { font-weight: $font-weight-base; }

  @media (min-width: #{$tagline-breakpoint + 1px}) {
    display: none;
  }
  @media (max-width: $tagline-breakpoint) {
    display: block;
  }
}

body.need-urgent-help .loadAmount {
  display: none;
}

body.need-urgent-help .listing .listContent:not(.easeIn) {
  display: none;
  .listedPost {
    height: 0;
    opacity: 0;
  }
}

// Gtranslate
.pageHeader .headerContent .mainCallToAction,
.pageHeader .headerContent .header-search { 
  margin-bottom: -$spacer;
}

#google_translate_element {
  position: absolute;
  // bottom: calc(100% + #{$header-search-margin-right} + 2px);
  top: $header-content-padding-top;
  right: $header-content-padding-right;
}
@media (min-width: #{$nav-breakpoint + 1px}) {
  .pageHeader:not(.sticky) #google_translate_element {
    top: #{$header-content-padding-top * 2.5};
  }
}
.goog-te-gadget-simple { display: flex; }
.goog-te-gadget-icon {
  height: 19px !important;
  width: 19px !important;
  max-width: none;
}
.goog-te-menu-value span:not(:first-of-type),
.goog-te-menu-value img {
  display: none;
}

// QA - Fall back to 2 column feature layout in IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and (min-width: 577px) {
  .homeFeatures {
    display: block !important;
  }

  .homeFeature {
    width: 47% !important;
    float: left !important;
  }
  .homeBox4, .homeBox5, .homeBox6 {
    width: 31% !important
  }
}

// https://raisingit.atlassian.net/browse/DES-5846
.homeBox1 h2 {
  color: $brand-primary;
}

// 1384318 SA - hyperlink text style
.post .postContent a:not([class*='cta']):not(.shareEmail):not(.facebook):not(.twitter):not(.buttonIcon):not([class*='button']),
.content.listingIntro a:not([class*='cta']) { 
  color: $brand-secondary;
  text-decoration: underline;
  &:hover {
    color: darken($brand-secondary,10%);
  }
}