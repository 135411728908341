// Home features
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 6,
    $prefab: 10
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card;
    @include card-basic;
  }
}


// Home feeds - overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


// Listed posts
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: $grey-lightest
  );
  @include card-basic;
}


// Listed products
.listedProduct {
  @include card;
  @include card-basic;
}